<template>
  <ListRow>
    <!-- 진행상태 -->
    <td>{{ rowData.status }}</td>
    <!-- 구매요청번호 -->
    <td>
      <button
        :class="['link_subject']"
        @click="$windowOpen(`${$routerPath.PURCHASE_VIEW}/${rowData.requestCid}`)"
      >
        {{ rowData.requestCid }}
      </button>
    </td>
    <!-- 요청명 -->
    <td class="td_ellip align_left" :data-ellip="rowData.requestTitle">
      {{ rowData.requestTitle }}
    </td>
    <!-- 요청부서 -->
    <td>{{ rowData.requestDeptName }}</td>
    <!-- 요청일자 -->
    <td>{{ rowData.requestDate | dateStringFormat }}</td>
    <!-- 요청담당자 -->
    <td>{{ `${rowData.requesterName}(${rowData.requester})` }}</td>
    <!-- 품목코드 -->
    <td class="align_left">{{ rowData.displayCode }}</td>
    <!-- 품목명 -->
    <td class="td_ellip align_left" :data-ellip="`${rowData.itemName}`">{{ rowData.itemName }}</td>
    <!-- 단위 -->
    <td>{{ rowData.unitValue }}</td>
    <!-- 통화 -->
    <td>{{ rowData.currency || 'KRW' }}</td>
    <!-- 요청수량 -->
    <td class="align_right">{{ rowData.requestQuantity | currency }}</td>
    <!-- 요청금액 -->
    <td class="align_right">{{ rowData.requestPrice | currency }}</td>
    <!-- 소비자가 -->
    <td class="align_right">{{ rowData.marketPrice | currency }}</td>
    <!-- 발주금액 -->
    <td class="align_right">{{ rowData.contractPrice | currency }}</td>
    <!-- 할인율(%) -->
    <td class="align_right">{{ rowData.savingsRate }}</td>
    <!-- 프로젝트명 -->
    <td
      class="td_ellip align_left"
      :data-ellip="`[${rowData.projectCode}] ${rowData.projectName}`"
      v-html="rowData.projectName"
    ></td>
    <!-- 발주번호 -->
    <td>
      <button
        class="link_subject"
        :data-ellip="rowData.partnerName || ''"
        @click="$windowOpen(`${$routerPath.ORDER_VIEW}/${rowData.orderCid}`)"
      >
        {{ rowData.orderCid || '' }}
      </button>
    </td>
    <!-- 발주서명 -->
    <td class="td_ellip align_left" :data-ellip="`${rowData.orderTitle}`">
      {{ rowData.orderTitle }}
    </td>
    <!-- 발주일자 -->
    <td>{{ rowData.orderDate | dateStringFormat }}</td>
    <!-- 구매담당자 -->
    <td>{{ `${rowData.purchaseName}(${rowData.purchase})` }}</td>
    <!-- 파트너사 -->
    <td class="td_ellip align_left">
      <button
        class="link_subject"
        :data-ellip="rowData.partnerName || ''"
        @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${rowData.partner}`)"
      >
        {{ rowData.partnerName || '' }}
      </button>
    </td>
    <!-- 계약(LEADTIME) -->
    <td>{{ `${rowData.leadTime === '0' ? '-' : `${rowData.leadTime}일`}` }}</td>
  </ListRow>
</template>

<script>
import ListRow from '@/components/shared/ListRow';
import ListLineMixin from '@/mixins/ListLineMixin';

export default {
  name: 'OrderListListLine',
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
  },
  computed: {
    // leadtime() {
    //   if (!this.rowData.발주일자 || !this.rowData.구매요청일자) return '';
    //   //발주일자 - 구매요청일자 = LEADTIME
    //   const days = this.$moment(this.rowData.구매요청일자).diff(
    //     this.$moment(this.rowData.발주일자),
    //     'days',
    //   );
    //   return `${days}일`;
    // },
  },
  methods: {},
};
</script>
