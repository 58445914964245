<template>
  <FilterBarBase
    :excelBtn="$isAdmin ? true : false"
    @onClickSearch="onClickSearch"
    @onClickExcelBtn="onClickExcelBtn"
    @onClickReset="onClickReset"
  >
    <dl class="w152">
      <dt>진행상태</dt>
      <dd>
        <SelectBox :dataList="statusList" :isDisabled="isDisabledStatus" :value.sync="statusId" />
      </dd>
    </dl>
    <dl>
      <dt>구매요청기간</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>

      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox
            style="width: 240px"
            :dataList="keywordDatas"
            :isDisabled="isDisabledStatus"
            :value.sync="keywordSelectedId"
          />
          <Input class="w437" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from '@/components/shared/filterBar/FilterBarBase';
import Input from '@/components/common/input/Input';
import Radio from '@/components/common/radio/Radio';
import SelectBox from '@/components/common/selectBox/SelectBox';
import CategoryGroupIncludeData from '@/components/common/selectBox/CategoryGroupIncludeData';
import CheckboxOnlyOne from '@/components/common/checkbox/CheckboxOnlyOne';
import DateRangeQuickSelect from '@/components/common/calendar/DateRangeQuickSelect';

import FilterBarMixin from '@/mixins/FilterBarMixin';
import DatePicker from '@/components/common/calendar/DatePicker';

export default {
  name: 'OrderListFilterBar',
  components: {
    DatePicker,
    FilterBarBase,
    Input,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
    CategoryGroupIncludeData,
    CheckboxOnlyOne,
  },
  mixins: [FilterBarMixin],
  data() {
    return {
      requestDate: '',
    };
  },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      this.$emit('onClickSearch', obj);
    },
    onClickReset() {
      this.onClickResetFilterBar();
    },
    onClickExcelBtn() {
      const isDatesSelected = this.dates && this.dates.length === 2;

      this.$emit('onClickExcelBtn', isDatesSelected);
    },
  },
};
</script>
