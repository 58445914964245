<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">구매요청현황</h2>
      </div>
      <div class="body_section">
        <OrderListFilterBar
          ref="filterBar"
          :statusList="statusList"
          :defaultStatusId="defaultStatusId"
          :keywordDatas="keywordList"
          :defaultDates="defaultDates"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          @onClickSearch="onClickSearch"
          @onClickExcelBtn="onClickExcelBtn"
        />
        <TableHead>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :isHorizontalScroll="true"
          :scrollWidth="'2600px'"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 진행상태 -->
            <col style="width: 100px" />
            <!-- 구매요청번호 -->
            <col style="width: 130px" />
            <!-- 요청명 -->
            <col />
            <!-- 요청부서 -->
            <col style="width: 120px" />
            <!-- 요청일자 -->
            <col style="width: 80px" />
            <!-- 요청담당자 -->
            <col style="width: 160px" />
            <!-- 품목코드 -->
            <col style="width: 120px" />
            <!-- 품목명 -->
            <col style="width: 120px" />
            <!-- 단위 -->
            <col style="width: 90px" />
            <!-- 통화 -->
            <col style="width: 60px" />
            <!-- 요청수량 -->
            <col style="width: 60px" />
            <!-- 요청금액 -->
            <col style="width: 100px" />
            <!-- 소비자가 -->
            <col style="width: 100px" />
            <!-- 발주금액 -->
            <col style="width: 100px" />
            <!-- 할인율(%) -->
            <col style="width: 70px" />
            <!-- 프로젝트명 -->
            <col style="width: 220px" />
            <!-- 발주번호 -->
            <col style="width: 130px" />
            <!-- 발주서명 -->
            <col style="width: 200px" />
            <!-- 발주일자 -->
            <col style="width: 80px" />
            <!-- 구매담당자 -->
            <col style="width: 160px" />
            <!-- 파트너사 -->
            <col style="width: 100px" />
            <!-- 계약(LEADTIME) -->
            <col style="width: 80px" />
          </template>
          <template v-slot:tr>
            <th>진행상태</th>
            <th>구매요청번호</th>
            <th>요청명</th>
            <th>요청부서</th>
            <th>요청일자</th>
            <th>요청담당자</th>
            <th>품목코드</th>
            <th>품목명</th>
            <th>단위</th>
            <th>통화</th>
            <th>요청수량</th>
            <th>요청금액</th>
            <th>소비자가</th>
            <th>발주금액</th>
            <th>할인율(%)</th>
            <th>프로젝트명</th>
            <th>발주번호</th>
            <th>발주서명</th>
            <th>발주일자</th>
            <th>구매담당자</th>
            <th>파트너사</th>
            <th>계약(LEADTIME)</th>
          </template>
          <template v-slot:row="slotProps">
            <OrderListListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="21" class="td_empty">통계 데이터가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>
<script>
import { mapState } from 'vuex';
import ApiService from '@/services/ApiService';
import PageWithLayout from '@/components/layout/PageWithLayout';
import PageMixin from '@/mixins/PageMixin';
import ListPageMixin from '@/mixins/ListPageMixin';
import TableHead from '@/components/shared/TableHead';
import SelectBoxPageRange from '@/components/common/selectBox/SelectBoxPageRange';
import ListPage from '@/components/shared/ListPage';
import OrderListFilterBar from '@LIG/components/admin/statistics/list/orderList/OrderListFilterBar';
import OrderListListLine from '@LIG/components/admin/statistics/list/orderList/OrderListListLine';
import { GET_ORDERLIST_LIST_ACTION } from '@LIG/store/modules/statistics/action';
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from '@/store/modules/progressbar/action';
import { downloadFile } from '@/utils/fileUtils';
import { makeQueryStringByObject } from '@/utils/urlUtils';

export default {
  name: 'OrderList',
  components: {
    OrderListFilterBar,
    OrderListListLine,
    ListPage,
    SelectBoxPageRange,
    TableHead,
    PageWithLayout,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: `${this.$apiPath.STATISTICS_ORDERITEM}`,
      defaultStatusId: '',
      defaultKeywordSelectedId: 'projectName',
    };
  },
  computed: {
    ...mapState({
      statusList: (state) => state.statistics.orderListStatuslist,
      keywordList: (state) => state.statistics.orderListKeywordlist,
    }),
  },
  created() {
    const params = this.checkUrlQuery();
    this.$store.dispatch(GET_ORDERLIST_LIST_ACTION).then(() => {
      this.getDataInit(params);
    });
  },
  methods: {
    checkUrlQuery() {
      const { query } = this.$route;
      const objPaging = this.checkQueryPaging(query);
      const objKeyword = this.checkQueryKeywords(query, this.keywordList);
      const objStatus = this.checkQueryStatus(query);
      // 날짜 검색이 좀 다름.
      const objDates = this.checkQueryDates(query, 'startDate', 'endDate');
      return Object.assign({}, objPaging, objKeyword, objDates, objStatus);
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, status, dateSelectedId } =
        objData;
      let params = {};
      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }
      if (startDt && endDt) {
        params['startDate'] = startDt;
        params['endDate'] = endDt;
      }
      if (status) {
        params.status = status;
      }
      return params;
    },
    async onClickExcelBtn() {
      this.$store.dispatch(PROGRESS_START_ACTION);
      const path = `${this.$apiPath.EXCEL}/contractSta`;
      const filterBar = this.$refs.filterBar;
      if (!filterBar) {
        this.$store.dispatch(PROGRESS_END_ACTION);
        return;
      }
      const objFilterBarData = filterBar.getCommonFilterbarData();
      const params = this.getFilterbarParams(objFilterBarData, true);
      const result = await ApiService.shared.get(`${path}${makeQueryStringByObject(params)}`, {
        isNeedAuth: true,
        responseType: 'arraybuffer',
      });

      this.$store.dispatch(PROGRESS_END_ACTION);

      downloadFile(result, `구매요청현황_${this.moment().format('YYYYMMDD')}.xlsx`);
    },
  },
};
</script>
